<template>
    <div>
        <v-autocomplete
            v-model="selected_item"
            :disabled="disabled"
            :error-messages="errorMessages"
            :items="items"
            :label="label"
            :no-data-text="$t('no_data')"
            item-text="name"
            item-value="uuid"
            outlined
            @blur="blur"
            @input="input"
        >

            <template v-slot:item="data">
                <template>
                    <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-btn elevation="0" fab small text @click.prevent.stop="itemClick(data.item.uuid)">
                            <v-icon>mdi-open-in-new</v-icon>
                        </v-btn>
                    </v-list-item-action>
                </template>
            </template>

        </v-autocomplete>
    </div>
</template>

<script>
export default {
    name: "ItemSelect",
    props: ["label", "value", "errorMessages", "disabled", "items", "route"],
    data() {
        return {
            selected_item: this.value,
            dialog: true
        };
    },
    watch: {
        value(val) {
            this.selected_item = val;
        },
        selected_item(val) {
            this.$emit("input", val);
        }
    },
    methods: {
        itemClick(uuid) {
            let routeData = this.$router.resolve({name: this.route, params: {uuid: uuid}});
            window.open(routeData.href, "_blank");
        },
        blur() {
            this.$emit("blur");
        },
        input() {
            this.$emit("inout");
        }
    }
};
</script>

<style scoped>

</style>