<template>
    <div class="mb-5">
        <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="-1"
            :no-data-text="$t('items.no_data')"
            class="elevation-1"
            hide-default-footer
        >
            <template v-slot:top>
                <div class="ml-4 mr-4 pt-2 items__header">
                    <h3>{{ label }}</h3>
                    <v-btn :disabled="disabled" @click="addItem">
                        <v-icon
                            left
                        >
                            mdi-plus
                        </v-icon>
                        {{ $t("items.add") }}
                    </v-btn>
                </div>
            </template>

            <template v-slot:item.name="{item}">
                <v-text-field
                    v-model="item.name"
                    :disabled="disabled"
                    :label="$t('items.description')"
                    :rules="[rules.required]"
                    class="mt-3"
                    outlined
                    @blur="update"
                    @input="update"
                ></v-text-field>
            </template>
            <template v-slot:item.quantity="{item}">
                <v-text-field
                    v-model="item.quantity"
                    :disabled="disabled"
                    :label="$t('items.quantity')"
                    :rules="[rules.required, rules.quantity]"
                    class="mt-3"
                    outlined
                    type="number"
                    @blur="update"
                    @input="update"
                ></v-text-field>
            </template>
            <template v-slot:item.tax="{item}">
                <v-text-field
                    v-model="item.tax"
                    :disabled="disabled"
                    :label="$t('items.tax')"
                    :rules="[rules.required, rules.tax]"
                    class="mt-3"
                    outlined
                    suffix="%"
                    @blur="update"
                    @input="update"
                ></v-text-field>
            </template>
            <template v-slot:item.price="{item}">
                <v-text-field
                    v-model="item.price"
                    :disabled="disabled"
                    :label="$t('items.price')"
                    :rules="[rules.required, rules.price]"
                    class="mt-3"
                    outlined
                    @blur="update"
                    @input="update"
                ></v-text-field>
            </template>
            <template v-slot:item.actions="{item}">
                <v-btn
                    :disabled="disabled"
                    class="mb-4"
                    color="error"
                    text
                    @click="removeItem(item)"
                >
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </template>

            <template v-slot:body.append>
                <tr>
                    <td colspan="2"></td>
                    <td class="pt-2 pb-2 text-right">
                        {{ $t("items.subtotal") }}:<br/>
                        {{ $t("items.tax") }}:<br/>
                        {{ $t("items.total") }}:
                    </td>
                    <td class="pt-2 pb-2">
                        {{ Utils.formatMoney(subtotal, currency, $i18n.locale) }}<br>
                        {{ Utils.formatMoney(tax, currency, $i18n.locale) }}<br>
                        {{ Utils.formatMoney(subtotal + tax, currency, $i18n.locale) }}
                    </td>
                    <td></td>
                </tr>
            </template>

        </v-data-table>
    </div>
</template>

<script>
import Utils from "../helpers/Utils";

export default {
    name: "Items",
    computed: {
        Utils() {
            return Utils;
        }
    },
    props: ["label", "value", "disabled", "currency"],
    data() {
        return {
            subtotal: 0,
            tax: 0,
            headers: [
                {text: this.$t("items.description"), value: "name", sortable: false, align: "start"},
                {text: this.$t("items.quantity"), value: "quantity", sortable: false},
                {text: this.$t("items.tax"), value: "tax", sortable: false},
                {text: this.$t("items.price"), value: "price", sortable: false},
                {text: "", value: "actions", sortable: false}
            ],
            items: this.value,
            rules: {
                required: value => !!value || this.$t("items.value_required"),
                quantity: value => value > 0 || this.$t("items.greater_than_zero"),
                tax: value => (value >= 0 && value <= 100) || this.$t("items.percentage"),
                price: value => (value !== 0) || this.$t("items.must_positive")
            }
        };
    },
    watch: {
        value(val) {
            this.items = val;
            this.calculate();
        }
    },
    methods: {
        addItem() {
            console.log("new line");
            this.items.push({
                name: null,
                quantity: null,
                tax: null,
                price: null
            });
            this.update();
        },
        removeItem(item) {
            this.$swal({
                title: this.$t("items.delete_title"),
                text: this.$t("items.delete_text"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: this.$t("items.delete_yes"),
                cancelButtonText: this.$t("items.delete_no"),
                confirmButtonColor: Utils.getColor(this, "error"),
                cancelButtonColor: Utils.getColor(this, "primary")
            }).then(result => {
                if (result.isConfirmed) {
                    this.items.splice(this.items.indexOf(item), 1);
                    this.update();
                }
            });
        },
        update() {
            this.$emit("input", this.items);
            this.calculate();
        },
        calculate() {
            this.subtotal = 0;
            this.tax = 0;
            for (const item of this.items) {
                if (item.quantity !== null && item.price !== null && item.tax !== null) {
                    this.subtotal = this.subtotal + (Utils.parseAmount(item.quantity) * Utils.parseAmount(item.price));
                    this.tax = this.tax + ((Utils.parseAmount(item.price) * Utils.parseAmount(item.quantity)) * (Utils.parseAmount(item.tax) / 100));
                }
            }
        }
    }
};
</script>

<style scoped>
.items__header {
    display: flex;
    justify-content: space-between;
}
</style>