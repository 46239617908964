var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-5"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":-1,"no-data-text":_vm.$t('items.no_data'),"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"ml-4 mr-4 pt-2 items__header"},[_c('h3',[_vm._v(_vm._s(_vm.label))]),_c('v-btn',{attrs:{"disabled":_vm.disabled},on:{"click":_vm.addItem}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.$t("items.add"))+" ")],1)],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"mt-3",attrs:{"disabled":_vm.disabled,"label":_vm.$t('items.description'),"rules":[_vm.rules.required],"outlined":""},on:{"blur":_vm.update,"input":_vm.update},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"mt-3",attrs:{"disabled":_vm.disabled,"label":_vm.$t('items.quantity'),"rules":[_vm.rules.required, _vm.rules.quantity],"outlined":"","type":"number"},on:{"blur":_vm.update,"input":_vm.update},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}})]}},{key:"item.tax",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"mt-3",attrs:{"disabled":_vm.disabled,"label":_vm.$t('items.tax'),"rules":[_vm.rules.required, _vm.rules.tax],"outlined":"","suffix":"%"},on:{"blur":_vm.update,"input":_vm.update},model:{value:(item.tax),callback:function ($$v) {_vm.$set(item, "tax", $$v)},expression:"item.tax"}})]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"mt-3",attrs:{"disabled":_vm.disabled,"label":_vm.$t('items.price'),"rules":[_vm.rules.required, _vm.rules.price],"outlined":""},on:{"blur":_vm.update,"input":_vm.update},model:{value:(item.price),callback:function ($$v) {_vm.$set(item, "price", $$v)},expression:"item.price"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mb-4",attrs:{"disabled":_vm.disabled,"color":"error","text":""},on:{"click":function($event){return _vm.removeItem(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}},{key:"body.append",fn:function(){return [_c('tr',[_c('td',{attrs:{"colspan":"2"}}),_c('td',{staticClass:"pt-2 pb-2 text-right"},[_vm._v(" "+_vm._s(_vm.$t("items.subtotal"))+":"),_c('br'),_vm._v(" "+_vm._s(_vm.$t("items.tax"))+":"),_c('br'),_vm._v(" "+_vm._s(_vm.$t("items.total"))+": ")]),_c('td',{staticClass:"pt-2 pb-2"},[_vm._v(" "+_vm._s(_vm.Utils.formatMoney(_vm.subtotal, _vm.currency, _vm.$i18n.locale))),_c('br'),_vm._v(" "+_vm._s(_vm.Utils.formatMoney(_vm.tax, _vm.currency, _vm.$i18n.locale))),_c('br'),_vm._v(" "+_vm._s(_vm.Utils.formatMoney(_vm.subtotal + _vm.tax, _vm.currency, _vm.$i18n.locale))+" ")]),_c('td')])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }