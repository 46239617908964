export default {
    get(locale, ignored = []) {

        if (locale === undefined || typeof locale !== "string") {
            locale = "en";
        }

        let translations = require("../assets/locales/" + locale + ".json");

        const statuses = [
            "draft", "sent", "viewed", "approved", "partial", "paid", "received", "cancelled", "voided", "denied", "refunded"
        ];

        let validStatuses = [];
        for (const status of statuses) {
            if (ignored.indexOf(status) === -1) {
                validStatuses.push({text: translations["status"][status], value: status});
            }
        }

        return validStatuses;
    }
};